function PrivateChat(initSettings) {
  var _this = this;
  var settings = {};
  var defaults = {
    "container": ".private-chat-html",
    "modalContainer": "#privatechat-modal",
    "modalCallContainer": "#privatechat-call-modal",
    "threadsContainer": ".threads-container",
    "language": {
      "page": "english",
      "browser": ""
    },
    "transport": {
      "serverUri": "localhost",
      "customParams": {}
    },
    "authorizedPerson": {
      "id": 0,
      "person": "",
      "screenName": ""
    }
  };
  var activeCall = {
    person: "",
    personId: 0,
    screenName: ""
  };
  var activeChat = {
    person: "",
    personId: 0,
    screenName: ""
  };

  var messagesLimit = 20;
  var currentMessagesPage = 0;
  var isLastMessagesPage = false;


  var modules = {};

  settings = $.extend(true, defaults, initSettings);
  var privateChatContId = settings.container,
      privateChatCont = $(privateChatContId);
  PrivateChat.instance = this;
  var privateChat = this;
  var translator = new PcMessageTranslator(this);



  this.reconnect = function() {
    if (modules.transport instanceof PcWebSocketTransport) {
      modules.transport = new PcWebSocketTransport(settings.transport);
      modules.videoPlayer.start();
    }
  };

  this.connect = function () {
    //first start
    if (!modules.transport) {
      modules['transport'] = new PcWebSocketTransport(settings.transport);
    }
  };

  this.getSettings = function () {
    return settings;
  };

  this.getChatContainer = function() {
    return privateChatCont;
  };

  this.getDialogs = function () {
    modules.transport.sendPacket(new PcChatPacketManager.PmGetDialogsPacket());
  };

  this.getAvatarUrl = function (person, id) {
    return Config.exec + "/postback/getavatar.jsp?height=60&width=60&id=" + (person == "customer" ? "viewer-"+id : "host-"+id);
  };

  this.convertToLocalDateTime = function (utcStr) {
    if (!utcStr) return "";
    const localDate = new Date(utcStr);
    return localDate.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  // Threads

  this.setActiveChat = function (person, personId, screenName) {
    currentMessagesPage = 0;
    isLastMessagesPage = false;

    activeChat = {
      person,
      personId,
      screenName
    };
  };

  this.unsetActiveChat = function () {
    _this.setActiveChat("", 0, "");
    const $topMenuCont = $(".direct-right .top-menu");
    const $historyCont = $(".direct-right .cont-history");
    $topMenuCont.html("");
    $historyCont.html(`
      <div class="label-no-message">
        <i class="fa fa-comments fa-regular"></i>
        <span>${I18n.t("Select a chat to start messaging")}</span>
      </div>
    `);
    privateChatCont.removeClass("thread-opened");
  };

  this.isActiveChatEnabled = function () {
    return activeChat.personId > 0;
  };

  this.getHistory = function (person, id) {
    if (isLastMessagesPage) return;
    modules.transport.sendPacket(new PcChatPacketManager.PmGetHistoryPacket(person, id, currentMessagesPage, messagesLimit));
    currentMessagesPage++;
  };

  this.printThreadItem = function (person) {
    const $cont = $(settings.threadsContainer);
    let html = `
      <li class="thread-item list-group-item js_privatechat-thread_item" data-person="${person.person}" data-person-id="${person.id}" data-screen-name="${person.screenName}" data-is-online="${person.isOnline}" data-is-streaming="${person.isStreaming}">
        <div class="thread-item-content">
          <div class="avatar position-relative"><img src="${_this.getAvatarUrl(person.person, person.id)}"/><div class="status ${person.isOnline ? "online" : "offline"}"></div></div>
          <div class="thread-info">
            <div class="thread-item-info">
              <div class="thread-item-screenname">${person.screenName}</div>
              <div class="thread-item-msg-info">
                <div class="d-flex gap-1">
                  <div class="last-msg text-truncate">${person.lastMessage?.data || "&nbsp;"}</div>
                </div>
              </div>
              <div class="thread-item-date">${_this.convertToLocalDateTime(person.lastMessage?.createdAt) || "&nbsp;"}</div>
            </div>
          </div>
          <div class="thread-item-close js_privatechat-thread_item_close" data-person="${person.person}" data-person-id="${person.id}" data-screen-name="${person.screenName}">✕</div>
        </div>
      </li>
    `;

    $cont.append(html);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("cmd") === "start-dialog") {
      let personId = urlParams.get("id");
      let personType = urlParams.get("person");
      if (personId == person.id && personType == person.person) {
        $(".js_privatechat-thread_item[data-person='" + personType + "'][data-person-id='" + personId + "']").click();
      }
    }
  };

  this.updateThreadItem = function (sender, senderId, receiver, receiverId, message, time) {
    let $threadItem = $(settings.threadsContainer).find(".thread-item[data-person='"+sender+"'][data-person-id='"+senderId+"']");
    if ($threadItem.length == 0) {
      $threadItem = $(settings.threadsContainer).find(".thread-item[data-person='"+receiver+"'][data-person-id='"+receiverId+"']");
    }
    if ($threadItem.length == 0) return;

    $threadItem.find(".thread-info .last-msg").html(message);
    $threadItem.find(".thread-info .date").html(_this.convertToLocalDateTime(time));
  };

  this.printPersonTopMenu = function (person, personId, screenName, isOnline, isStreaming) {
    const $cont = $(".direct-right .top-menu");
    let html = `
      <button class="btn btn-close-thread js-close-thread"><i class="fa fa-chevron-left"></i></button>
      <div class="avatar position-relative"><img src="${_this.getAvatarUrl(person, personId)}"/><div class="status ${isOnline ? "online" : "offline"}"></div></div>
      <div>
        <div class="sn fw-bolder">${screenName}</div>
        <div class="thread-type ${isOnline ? "online" : "offline"}">${isOnline ? I18n.t("Online") : I18n.t("Offline")}</div>
      </div>
    `;
    if (person == "performer") {
      html += `
        <div class="headline-icons">
          <button class="btn js_privatechat-model-bio" data-bs-toogle="tooltip" title="${I18n.t("Profile")}"><i class="fa fa-user"></i></button>
          <button class="btn js_privatechat-model-freechat ${isStreaming? "" : "d-none"}" data-bs-toogle="tooltip" title="${I18n.t("Video Chat")}"><i class="fa fa-video-camera"></i></button>
<!--          <button class="d-none btn js-call-btn ${isOnline ? "js_privatechat-start_call online" : "offline"}" data-person="${person}" data-person-id="${personId}" data-screen-name="${screenName}" data-is-online="${isOnline}" data-is-streaming="${isStreaming}">-->
<!--            <i class="fa fa-video-camera"></i>-->
<!--          </button>-->
          <div class="make_tip">          
            <button class="btn" data-bs-toggle="modal" data-bs-target="#sendTipModal" data-bs-toogle="tooltip" title="${I18n.t("Send Tip")}"><i class="fa fa-gift"></i></button>
            <div class="modal fade" id="sendTipModal" tabindex="-1" aria-labelledby="sendTipModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="make-tip-form">
                    <form class="js-send_tip" onsubmit="return false;">
                      <input type="text" class="tip-amount" placeholder=${I18n.t("Enter amount")}>
                      <button type="submit" data-bs-dismiss="modal"></button>
                    </form>
                    <form class="js-send_tip" onsubmit="return false;">
                      <input type="hidden" class="tip-amount" value="1">
                      <button type="submit" data-bs-dismiss="modal">1 ${I18n.t("credit")}</button>
                    </form>                    
                    <form class="js-send_tip" onsubmit="return false;">
                      <input type="hidden" class="tip-amount" value="2">
                      <button type="submit" data-bs-dismiss="modal">2 ${I18n.t("credit")}</button>
                    </form>
                    <form class="js-send_tip" onsubmit="return false;">
                      <input type="hidden" class="tip-amount" value="5">
                      <button type="submit" data-bs-dismiss="modal">5 ${I18n.t("credit")}</button>
                    </form>
                    <form class="js-send_tip" onsubmit="return false;">
                      <input type="hidden" class="tip-amount" value="10">
                      <button type="submit" data-bs-dismiss="modal">10 ${I18n.t("credit")}</button>
                    </form>                
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-translate hidden" onclick="return false;" title='${I18n.t("Auto-translate")}'><i class="fa fa-language"></i></button>
        </div>
      `;
    } else if (person == "customer") {
      html += `
        <div class="headline-icons">
          <button class="d-none js-call-btn nav-link ${isOnline ? "js_privatechat-start_call online" : "offline"}" data-person="${person}" data-person-id="${personId}" data-screen-name="${screenName}" data-is-online="${isOnline}" data-is-streaming="${isStreaming}"><i class="fa fa-video-camera"></i></button>
          <button class="btn btn-translate hidden" onclick="return false;" title='${I18n.t("Auto-translate")}'><i class="fa fa-language"></i></button>
        </div>
      `;
    }

    $cont.html(html);
  };

  this.printHistory = function (messages) {
    const $cont = $(".direct-right .cont-history");
    let html = `<div class="label-no-message">${I18n.t("No Messages")}</div>`;
    if (messages.length > 0) {
      html = ``;
    }
    $cont.html(html);

    messages.reverse().forEach((message) => {
      _this.printMessage(message.senderType, message.senderId, message.receiverType, message.receiverId, message.data, message.createdAt, 0, true);
    });
  };

  this.printMessage = function (sender, senderId, receiver, receiverId, message, time, price, appendBottom) {
    const $cont = $(".direct-right .cont-history");
    let html = "";
    if (!_this.isActiveChatEnabled()) return;
    if (!(activeChat.person == sender && activeChat.personId == senderId) && !(activeChat.person == receiver && activeChat.personId == receiverId)) return;

    if (sender == settings.authorizedPerson.person && senderId == settings.authorizedPerson.id) {
      html = `
        <div class="msg">
          <div class="msg-body">
            <div class="text">${message}</div>
            <div class="price">${price ? I18n.t("{1} cr", [price]) : ""}</div>
          </div>
          <div class="time">${_this.convertToLocalDateTime(time)}</div>
        </div>
      `;
    } else {
      html = `
        <div class="msg mU">
          <div class="msg-body">
            <div class="sn">${activeChat.screenName}</div>
            <div class="text">${message}</div>
          </div>
          <div class="time">${_this.convertToLocalDateTime(time)}</div>
        </div>
      `;
    }

    var el = $(html);
    if (!(sender == settings.authorizedPerson.person && senderId == settings.authorizedPerson.id)) {
      translator.trigger("translate", {container: el, message: message, destLanguage: settings.language.page});
    }

    if (appendBottom) {
      $cont.append(el);
      $cont.scrollTop($cont.prop("scrollHeight")); // scroll down
    } else {
      $cont.prepend(el);
    }
    $('.label-no-message').remove();
  };

  this.printSystemMessage = function (sender, senderId, receiver, receiverId, message) {
    const $cont = $(".direct-right .cont-history");
    let html = "";
    if (!_this.isActiveChatEnabled()) return;
    if (!(activeChat.person == sender && activeChat.personId == senderId) && !(activeChat.person == receiver && activeChat.personId == receiverId)) return;
    html = `
      <div class="msg mS">
        <div class="msg-body">
          <div class="text">${message}</div>
        </div>
      </div>
    `;
    $cont.append(html);
    $cont.scrollTop($cont.prop("scrollHeight")); // scroll down
    $('.label-no-message').remove();
  };

  this.redirectToVideoChat = function () {
    // need time to init broadcast page on performer side
    setTimeout(() => {
      _this.hideCallModal();
      _this.hideModal();
      window.location.replace(Config.exec + "/postback/privatechat.jsp?page=chat&mode=private&performerId=" + activeCall.personId);
    }, 2000);
  };

  // Calls

  this.setActiveCall = function (person, personId, screenName) {
    activeCall = {
      person,
      personId,
      screenName
    };
  };

  this.startCall = function (receiver, receiverId, receiverScreenName) {
    modules.transport.sendPacket(new PcChatPacketManager.PmStartCallPacket(receiver, receiverId));
    _this.setActiveCall(receiver, receiverId, receiverScreenName);
  };

  this.stopCall = function (receiver, receiverId) {
    modules.transport.sendPacket(new PcChatPacketManager.PmStopCallPacket(receiver, receiverId));
  };

  this.acceptCall = function (receiver, receiverId) {
    modules.transport.sendPacket(new PcChatPacketManager.PmAcceptCallPacket(receiver, receiverId));
  };

  this.declineCall = function (receiver, receiverId) {
    modules.transport.sendPacket(new PcChatPacketManager.PmDeclineCallPacket(receiver, receiverId));
  };

  this.showCallModal = function (html, isClosable) {
    const $modal = $(settings.modalCallContainer);
    $modal.modal("dispose");
    $modal.html(html);
    if (!isClosable) {
      $modal.modal({backdrop: "static", keyboard: false});
    }
    $modal.modal("show");
  };

  this.hideCallModal = function () {
    const $modal = $(settings.modalCallContainer);
    $modal.modal("hide");
    $modal.modal("dispose");
  };

  this.showModal = function (html, isClosable) {
    const $modal = $(settings.modalContainer);
    $modal.modal("dispose");
    $modal.html(html);
    if (!isClosable) {
      $modal.modal({backdrop: "static", keyboard: false});
    }
    $modal.modal("show");
  };

  this.hideModal = function () {
    const $modal = $(settings.modalContainer);
    $modal.modal("hide");
    $modal.modal("dispose");
  };

  this.showYouAreCallingModal = function (name, person, personId) {
    let html = `
      <div class="modal-dialog">
        <div class="modal-content" style="background-image: url(${_this.getAvatarUrl(person, personId)})">
          <div class="modal-body">
            <div class="title">
              <p>${I18n.t("You are calling")}</p>
              <p>${name}</p>
            </div>
            <div class="controls">
              <button class="decline-call w-text js_privatechat-stop_call"><div>${I18n.t("Cancel")}</div></button>
            </div>
          </div>
        </div>
      </div>
    `;

    _this.showCallModal(html, false);
  };

  this.showYouBeingCalledModal = function (name, person, personId) {
    let html = `
      <div class="modal-dialog">
        <div class="modal-content" style="background-image: url(${_this.getAvatarUrl(person, personId)})">
          <div class="modal-body">
            <div class="title">
              <p>${I18n.t("You are being called by")}</p>
              <p>${name}</p>
            </div>
            <div class="controls">
              <button class="accept-call js_privatechat-accept_call"></button>
              <button class="decline-call js_privatechat-decline_call"></button>
            </div>
          </div>
        </div>
      </div>
    `;

    _this.showCallModal(html, false);
  };

  this.showStartingPrivateChatModal = function () {
    let html = `
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">${I18n.t("Starting Private Chat")}</h4>
          </div>
          <div class="modal-body">
            <button class="btn primary js_privatechat-waiting_response_call">${I18n.t("WAITING RESPONSE")}</button>
            <span class="or-delimiter">${I18n.t("OR")}</span>
            <button class="btn primary js_privatechat-take_a_peek">${I18n.t("TAKE A PEEK NOW")}</button>
          </div>
        </div>
      </div>
    `;

    _this.showModal(html, false);
  };

  this.showPrivateChatDeclinedModalV1 = function () {
    let html = `
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">${I18n.t("Private Chat Declined")}</h4>
          </div>
          <div class="modal-body">
            <button class="btn secondary js_privatechat-model-bio">${I18n.t("MODEL'S BIO")}</button>
            <button class="btn secondary js_privatechat-to-models">${I18n.t("BACK TO MODEL'S")}</button>
          </div>
        </div>
      </div>
    `;

    _this.showModal(html, true);
  };

  this.showPrivateChatDeclinedModalV2 = function () {
    let html = `
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">${I18n.t("Private Chat Declined")}</h4>
          </div>
          <div class="modal-body">
            <button class="btn primary js_privatechat-take_a_peek">${I18n.t("TAKE A PEEK NOW")}</button>
            <button class="btn secondary js_privatechat-model-bio">${I18n.t("MODEL'S BIO")}</button>
            <button class="btn secondary js_privatechat-to-models">${I18n.t("BACK TO MODEL'S")}</button>
          </div>
        </div>
      </div>
    `;

    _this.showModal(html, true);
  };


  // Listeners

  $(document).on("click", ".js_privatechat-start_call", function() {
    _this.startCall($(this).data("person"), $(this).data("personId"), $(this).data("screenName"));
    if ($(this).data("isStreaming")) {
      _this.showStartingPrivateChatModal();
    } else {
      _this.showYouAreCallingModal($(this).data("screenName"), $(this).data("person"), $(this).data("personId"));
    }
  });

  $(document).on("click", ".js_privatechat-accept_call", function() {
    _this.acceptCall(activeCall.person, activeCall.personId);
    _this.hideCallModal();
    if (settings.authorizedPerson.person == "performer") {
      privateChat.trigger("Action:PmAcceptCall");
    } else if (settings.authorizedPerson.person == "customer" && activeCall.person == "performer") {
      _this.redirectToVideoChat();
    }
  });

  $(document).on("click", ".js_privatechat-decline_call", function() {
    _this.declineCall(activeCall.person, activeCall.personId);
    _this.hideCallModal();
  });

  $(document).on("click", ".js_privatechat-stop_call", function() {
    _this.stopCall(activeCall.person, activeCall.personId);
    _this.hideCallModal();
  });

  $(document).on("click", ".js_privatechat-waiting_response_call", function() {
    _this.hideModal();
    _this.showYouAreCallingModal(activeCall.screenName, activeCall.person, activeCall.personId);
  });

  $(document).on("click", ".js_privatechat-take_a_peek", function() {
    _this.stopCall(activeCall.person, activeCall.personId);
    window.location.replace(Config.exec + "/postback/privatechat.jsp?page=chat&performerId=" + activeCall.personId);
  });

  $(document).on("click", ".js_privatechat-model-bio", function() {
    _this.stopCall(activeCall.person, activeCall.personId);
    window.location = Config.exec + "/postback/privatechat.jsp?page=bio&performerId=" + activeChat.personId;
  });

  $(document).on("click", ".js_privatechat-model-freechat", function() {
    _this.stopCall(activeCall.person, activeCall.personId);
    window.location = Config.exec + "/postback/privatechat.jsp?page=freechat&performerId=" + activeChat.personId;
  });


  $(document).on("click", ".js_privatechat-to-models", function () {
    window.location.replace(Config.exec);
  });

  privateChatCont.on("submit", ".js_privatechat-search-dialog", function(e) {
    e.preventDefault();
    let queryString = $(this)[0].elements["qs"].value.toLowerCase();
    $(".threads-container li").show().filter(function() {
      return $(this).find(".thread-item-screenname").text().toLowerCase().trim().indexOf(queryString) == -1;
    }).hide();
    return false;
  });

  privateChatCont.on("click", ".js_privatechat-thread_item", function (e) {
    _this.setActiveChat($(this).data("person"), $(this).data("personId"), $(this).data("screenName"));
    _this.getHistory(activeChat.person, activeChat.personId);

    $(".js_privatechat-thread_item").removeClass("active");
    $(this).addClass("active");
    privateChatCont.addClass("thread-opened");
    _this.printPersonTopMenu($(this).data("person"), $(this).data("personId"), $(this).data("screenName"), $(this).data("isOnline"), $(this).data("isStreaming"));
    $("body").trigger("PcMessageTranslator.Init", [privateChat]);
  });

  privateChatCont.on("click", ".js_privatechat-thread_item_close", function (e) {
    e.stopPropagation();
    if (confirm(I18n.t("Do you really want to close dialog with {1}?", [$(this).data("screenName")]))) {
      modules.transport.sendPacket(new PcChatPacketManager.PmCloseDialogPacket($(this).data("person"), $(this).data("personId")));
      _this.unsetActiveChat();
    }
  });

  privateChatCont.on("click", ".js-send-message", function (e) {
    let message = $(this).parent().find(".js_privatechat-send_message").val();
    if (_this.isActiveChatEnabled() && message.trim().length > 0) {
      modules.transport.sendPacket(new PcChatPacketManager.PmMessagePacket(activeChat.person, activeChat.personId, message));
    }
    $(this).parent().find(".js_privatechat-send_message").val("");
  });

  privateChatCont.on("keyup", ".js_privatechat-send_message", function (e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      let message = e.target.value;
      e.target.value = "";
      if (_this.isActiveChatEnabled() && message.trim().length > 0) {
        modules.transport.sendPacket(new PcChatPacketManager.PmMessagePacket(activeChat.person, activeChat.personId, message));
      }
    }
  });

  privateChatCont.on("submit", ".modal .js-send_tip", function(e) {
    var sendTip = $(".make_tip");
    var el_amount = $(this).find(".tip-amount"),
        amount = parseFloat(el_amount.val().replace(",", "."));

    //validate
    if (isNaN(amount) || amount <= 0) {
      alert(I18n.t("Please specify correct amount"));
      return false;
    }
    var icon = sendTip.find("a > i.fa");
    //make tip
    jQuery.ajax({
      "dataType": "json",
      "type": "POST",
      "url": Config.exec+"/_ajaj-webvideo-gateway.jsp",
      "data": {
        "command": "customer-make-tip",
        "performer_id": activeChat.personId,
        "amount": amount
      },
      "success" : function(obj) {

        icon.addClass("fa-check blink").removeClass("fa-gift");
        setTimeout(function() {
          icon.addClass("fa-gift").removeClass("fa-check blink");
        }, 2500);

        if (obj.res) {
          if (typeof(accountBalance_createExpencesByPaidMessage) == "function") accountBalance_createExpencesByPaidMessage(obj.amount);
          modules.transport.sendPacket(new PcChatPacketManager.PmMessagePacket(activeChat.person, activeChat.personId, I18n.t("tipped {1} credits", ["" + amount])));
        }

      },
      "error" : function() {
        icon.addClass("fa-check blink").removeClass("fa-times");
        alert(I18n.t("Connection timeout. Please try later."));
      }
    });

    this.reset();
    return false;
  });

  // Received packets listeners

  this.bind("PmMessagePacket", function (packet) {
    _this.printMessage(packet.sender, packet.senderId, packet.receiver, packet.receiverId, packet.message, packet.additionalData?.createdAt, packet.additionalData?.price, true);
    _this.updateThreadItem(packet.sender, packet.senderId, packet.receiver, packet.receiverId, packet.message, packet.additionalData?.createdAt);
    if ($(".thread-item[data-person='"+packet.sender+"'][data-person-id='"+packet.senderId+"']").length == 0 &&
      (settings.authorizedPerson.person == packet.sender && settings.authorizedPerson.id != packet.senderId)
    ) {
      _this.getDialogs();
    }
    if (packet.additionalData?.price) {

      if (settings.authorizedPerson.person == packet.sender) {
        if (SiteUtils.getAccountBalance() >= packet.additionalData?.price) SiteUtils.reduceAccountBalance(packet.additionalData?.price);
      } else {
        SiteUtils.increaseAccountBalance((packet.additionalData?.price * settings.authorizedPerson.percent).toFixed(2))
      }
    }
  });

  this.bind("PmSystemMessagePacket", function (packet) {
    _this.printSystemMessage(packet.sender, packet.senderId, packet.receiver, packet.receiverId, packet.message);
  });

  this.bind("PmHistoryPacket", function (packet) {
    if (packet.messages.length < messagesLimit) isLastMessagesPage = true;
    if (currentMessagesPage == 1) {
      _this.printHistory(packet.messages);
    } else {
      packet.messages.forEach((message) => {
        _this.printMessage(message.senderType, message.senderId, message.receiverType, message.receiverId, message.data, message.createdAt, 0, false);
      });
    }
  });

  this.bind("PmDialogsPacket", function(packet) {
    $(settings.threadsContainer).html("");
    packet.persons?.forEach((person) => _this.printThreadItem(person));
  });

  this.bind("PmStartCallPacket", function(packet) {
    _this.setActiveCall(packet.sender, packet.senderId, "");
    _this.showYouBeingCalledModal(packet.senderScreenName, activeCall.person, activeCall.personId);
  });

  this.bind("PmAcceptCallPacket", function (packet) {
    if (settings.authorizedPerson.person == "customer" && packet.sender == "performer") {
      _this.redirectToVideoChat();
    } else if (settings.authorizedPerson.person == "performer") {
      privateChat.trigger("Action:PmAcceptCall");
    }
  });

  this.bind("PmDeclineCallPacket", function (packet) {
    _this.hideCallModal();
    _this.hideModal();
    if (packet?.additionalData["isStreamOnline"]) {
      _this.showPrivateChatDeclinedModalV2()
    } else {
      _this.showPrivateChatDeclinedModalV1();
    }
  });

  this.bind("PmStopCallPacket", function (packet) {
    _this.hideCallModal();
    _this.hideModal();
  });

  this.bind("PmPersonChangeStatusPacket", function (packet) {
    if (packet.status == "online" || packet.status == "offline") {
      let $threadItem = $(".thread-item[data-person='"+packet.person+"'][data-person-id='"+packet.personId+"']");
      $threadItem.data("isOnline", packet.status == "online" ? true : false);
      $threadItem.find(".status").attr("class", "status " + packet.status);
      $threadItem.find(".js-call-btn").attr("class", packet.status == "online" ? "d-none js-call-btn btn js_privatechat-start_call online" : "d-none js-call-btn btn offline");
      $threadItem.find(".js-call-btn").data("isOnline", packet.status == "online" ? true : false);
      if (activeChat.person == packet.person && activeChat.personId == packet.personId) {
        $(".top-menu").find(".avatar .status").attr("class", "status " + packet.status);
        $(".top-menu").find(".thread-type").attr("class", "thread-type " + packet.status);
        $(".top-menu").find(".thread-type").html(packet.status == "online" ? I18n.t("Online") : I18n.t("Offline"));
        $(".top-menu").find(".js-call-btn").attr("class", packet.status == "online" ? "d-none js-call-btn btn js_privatechat-start_call online" : "d-none js-call-btn btn offline");
        $(".top-menu").find(".js-call-btn").data("isOnline", packet.status == "online" ? true : false);
      }
    } else if (packet.status == "stream-online" || packet.status == "stream-offline") {
      let $threadItem = $(".thread-item[data-person='"+packet.person+"'][data-person-id='"+packet.personId+"']");
      $threadItem.data("isStreaming", packet.status == "stream-online" ? true : false);
      $threadItem.find(".js-call-btn").data("isStreaming", packet.status == "stream-online" ? true : false)
      if (activeChat.person == packet.person && activeChat.personId == packet.personId) {
        $(".top-menu").find(".js-call-btn").data("isStreaming", packet.status == "stream-online" ? true : false);
      }
    }
  });

  // Other listeners

  $(".direct-right .cont-history").bind("scroll", function (e) {
    if (this.scrollTop == 0 && _this.isActiveChatEnabled()) {
      _this.getHistory(activeChat.person, activeChat.personId);
      this.scrollTop = 1;
    }
  });


  $("body").trigger("PrivateChatInstanceInited", [privateChat]);
  $("body").trigger("PcMessageTranslator.Init", [privateChat]);
}

PrivateChat.getInstance = function(initSettings) {
  if (!PrivateChat.instance) {
    new PrivateChat(initSettings);
  }
  return PrivateChat.instance;
};

MicroEvent.mixin(PrivateChat);