function PcChatUtils() {}

PcChatUtils.isMouseInContainer = function(contObj, pageX, pageY) {
    var el = $(contObj),
        offset = el.offset();
    return (pageX >= offset.left && pageX <= (offset.left+el.width()) ) &&
           (pageY >= offset.top && pageY <= (offset.top+el.height()) );
};


PcChatUtils.sjReplaceAll = function (text, search, replace) {
  return text.split(search).join(replace);
};

PcChatUtils.setItem = function (name, value) {
  $.cookie('privatechat_'+name, ''+value, {'useLocalStorage': true});
};

PcChatUtils.getItem = function (name, defvalue) {
  return StringUtils.NVL(
    $.cookie('privatechat_'+name, {'useLocalStorage': true}),
    defvalue
  );
};

PcChatUtils.formatMoney = function(amount) {
  return accounting.formatMoney(amount, "", amount%1 != 0 ? 2 : 0, " ", ".");
};

PcChatUtils.formatDuration = function(ms) {
  var lpad = function (n) {
    return n<10 ? "0"+n :n;
  };
  var seconds = Math.floor((ms / 1000) % 60),
      minutes = Math.floor((ms / 60000) % 60),
      hours = Math.floor((ms / 3600000) % 24),
      days = Math.floor(ms / 86400000),
      txt = "";

  if (days > 0) txt += days +" "+I18n.t("day(s)")+" ";
  if (hours > 0) txt += lpad(hours)+":";
  txt += (minutes > 0) ? lpad(minutes)+":" : "00:";
  txt += (seconds > 0) ? lpad(seconds): "00";
  return txt;
};